
import {computed, defineComponent, ref} from "vue";
import { Form,Field,ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import MasterConfig from "@/core/config/MasterConfig";

interface NewFileData {
    fileTitle: string;
    fileDescription: string;
    fileStatus: string;
}
export default defineComponent({
    name: "new-file-modal",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    props: {
        fileType: String,
        fileParentID: String,
        fileChildID: String,
        fileSubChildID: String,
    },
    methods:{
    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newFileModalRef = ref<null | HTMLElement>(null);
        const fileInputs = ref<null | HTMLElement>(null);
        const supportedFiles = MasterConfig.upload.format;
        const chkFileSize = ref(true);
        const chkFileExt = ref(true);
        const chkFile = ref(true);

        const newFileData = ref<NewFileData>({
            fileTitle: "",
            fileDescription: "",
            fileStatus:"",
        });

        const validationSchema = Yup.object().shape({
            fileTitle: Yup.string().required().label("Dosya Başlığı"),
        });

        const insertItem = () => {
            let myFiles = document.getElementById("fileInputs") as HTMLInputElement;
            if(myFiles.files[0]) {
                let myExt = "." + (myFiles.files[0].name).split('.').pop();
                let mySize = myFiles.files[0].size;
                chkFile.value=true;
                chkFileExt.value = MasterConfig.upload.format.includes(myExt);
                chkFileSize.value = (MasterConfig.upload.maxSize >= mySize) ? true : false;
            }else{
                chkFile.value=false;
                return;
            }
            if(!chkFileExt.value || !chkFileSize.value){
                return;
            }
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");
            let newFileName = myFiles.files[0].name.replace(/[^0-9a-zA-Z.]/g, "");
            const payload2 = {
                Title: newFileData.value.fileTitle,
                Description: newFileData.value.fileDescription,
                Type: props.fileType,
                ParentID: props.fileParentID,
                ChildID: (props.fileChildID) ? props.fileChildID : null,
                SubChildID: (props.fileSubChildID) ? props.fileSubChildID : null,
                FileName: newFileName,
            }
            console.log(payload2);
            let formData = new FormData();
            formData.append("file", myFiles.files[0], newFileName);
            fetch(MasterConfig.general.uploadPath, {
                method: "POST",
                body: formData,
            }).then(() => {
                store.dispatch(Actions.FILES_INSERT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        });
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                    })
                    .catch(() => {
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");

                        Swal.fire({
                            text: store.getters.getFilesErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!-01",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });
            }).catch(() => {
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                Swal.fire({
                    text: "Dosya Yükleme işlemi sırasında bir hata oluştu.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!-02",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
            });
        }
        return {
            newFileData,
            fileInputs,
            validationSchema,
            submitButtonRef,
            newFileModalRef,
            insertItem,
            supportedFiles,
            chkFileSize,
            chkFileExt,
            chkFile,
        };
    },
});
