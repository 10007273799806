
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";

import ItemUpdate from "@/components/account/AccountKickBackUpdate.vue";
import ItemAddNew from "@/components/account/AccountKickBackAddNew.vue";

export default defineComponent({
    name: "overview",
    components: {
        ItemAddNew,
        ItemUpdate,
    },
    props: {
        accountID: String,
    },
    setup(props) {

        const payloadAccountID = {
            AccountID: props.accountID
        }

        store.dispatch(Actions.ACCOUNT_KICKBACK_LIST, payloadAccountID);
        const myList = computed(() => {
            return store.getters.accountKickBackList;
        });

        const updateActive = (ID, Active) => {
            const payload = {
                ID: ID,
                AccountID: props.accountID,
                is_active: (Active == 'active') ? "passive" : "active",
            }
            store.dispatch(Actions.ACCOUNT_KICKBACK_EDIT, payload)
        }

        const deleteItem = (ID) => {
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload = {
                        ID: ID,
                        AccountID: props.accountID,
                    }
                    store.dispatch(Actions.ACCOUNT_KICKBACK_DELETE, payload)
                        .then(() => {
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getAccountKickBackErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });

        }

        let itemID = ref<number>(0);
        function setItems(ID) {
            itemID.value=ID;
        }



        return {
            myList,
            dateConvert,
            itemID,
            setItems,
            updateActive,
            deleteItem,
        };


    },
});
