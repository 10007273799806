
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import {dateConvert} from "@/core/filters/datatime";
import monthsList from "@/core/data/months";

interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemMarket: string;
    itemHotel: string;
    itemAdKickBackTargetAmount: string;
}

export default defineComponent({
    name: "update-item-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    methods: {
    },
    props: {
        accountID: String,
        itemID: String,
    },
    watch: {
        itemID() {
            const payloadit = {
                "ID": this.itemID
            };
            this.hotelTagAddStatus = false;
            this.marTagAddStatus = false;
            store.dispatch(Actions.ACCOUNT_KICKBACK_DETAILS, payloadit);
        },
    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);

        const arrAdKickBackTargetAmount = ref ([
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
        ]);


        function setDatePicker(sDate,fDate){
            let myConfigS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: sDate,
            };

            let myConfigF = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: fDate,
            };

            let tmpFinishDate = flatpickr("#updateFinishDate", myConfigF);
            let tmpStartDate = flatpickr("#updateStartDate", myConfigS);

        }
        const myDetails2 = computed(() => {
            return store.getters.accountDetails;
        });

        const myDetails = computed(() => {
            let tmpArry = store.getters.accountKickBackDetails;
            if(tmpArry.ID!==undefined) {
                setData(tmpArry);

                if (tmpArry.StartDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.StartDate), dateConvert.method.convertToDate(tmpArry.FinishDate))
            }
            return tmpArry;

        });

        const setData = (tmpArry) => {
            newItemData.value.itemTitle = tmpArry.Title;
            newItemData.value.itemStart = dateConvert.method.dateToflatpicker(tmpArry.StartDate);
            newItemData.value.itemFinish = dateConvert.method.dateToflatpicker(tmpArry.FinishDate);
            newItemData.value.itemMarket = tmpArry.Markets;
            newItemData.value.itemHotel = tmpArry.Hotels;
            newItemData.value.itemAdKickBackTargetAmount = JSON.parse(tmpArry.AdKickBackTargetAmount);
            arrAdKickBackTargetAmount.value = [];
            for (let i=0; i<newItemData.value.itemAdKickBackTargetAmount.length; i++){
                arrAdKickBackTargetAmount.value.push(newItemData.value.itemAdKickBackTargetAmount[i] as any);
            }
        }

        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemMarket: "",
            itemHotel: "",
            itemAdKickBackTargetAmount: "",
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Bitiş Tarihi"),
        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            const payloadUpdateKickBack = {
                ID: props.itemID,
                AccountID: props.accountID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                Markets: (typeof newItemData.value.itemMarket !="string") ? (JSON.stringify(newItemData.value.itemMarket)).replace(/\\/g, '') : '""',
                Hotels: (typeof newItemData.value.itemHotel !="string") ? (JSON.stringify(newItemData.value.itemHotel)).replace(/\\/g, '') : '""',
                AdKickBackTargetAmount: (typeof newItemData.value.itemAdKickBackTargetAmount !="string") ? (JSON.stringify(newItemData.value.itemAdKickBackTargetAmount)).replace(/\\/g, '') : '""',
            }


            store.dispatch(Actions.ACCOUNT_KICKBACK_EDIT, payloadUpdateKickBack)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getAccountKickBackErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.HOTEL_LIST_ALL, {});
        const myHotels = computed(() => {
            return store.getters.hotelList;
        });


        let marTagAddStatus = false;
        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data && myDetails.value.ID) {
                let tmpList = [];
                let tmpSelList = [];
                let selData = JSON.parse(myDetails.value.Markets);
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myMarkets.value.data[i].ID) {
                            tmpSelList[is] = {
                                value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                                title: (myMarkets.value.data[i].ShortTitle).trim(),
                                cur: myMarkets.value.data[i].Cur,
                                type: 'market',
                                ID: myMarkets.value.data[i].ID,
                            };
                        }
                    }
                }

                if(marTagAddStatus == false) {
                    setTagInput(tmpList)
                    marTagAddStatus=true;
                }
                let tags = (document.getElementById('myTagsMarkets') as HTMLInputElement);
                if(tags.value!='')tagifyMarket.removeAllTags();
                if(tmpSelList.length>0) {
                    tagifyMarket.addTags(tmpSelList);
                }

                return tmpList;

            } else {
                return [];
            }
        });

        let hotelTagAddStatus = false;
        let tmpSelList = [];
        const myTagsHotels = computed(() => {
            if (myHotels.value.data !== undefined && myDetails.value.ID) {
                let tmpList = [];

                let selData = JSON.parse(myDetails.value.Hotels);

                for (let i = 0; i < myHotels.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myHotels.value.data[i].Title).trim(),
                        title: (myHotels.value.data[i].Title).trim(),
                        type: 'Hotels',
                        ID: myHotels.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myHotels.value.data[i].ID) {
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            tmpSelList[is] = {
                                value: (myHotels.value.data[i].Title).trim(),
                                title: (myHotels.value.data[i].Title).trim(),
                                type: 'Hotels',
                                ID: myHotels.value.data[i].ID,
                            };
                        }

                    }
                }
                if(hotelTagAddStatus==false){
                    setTagInputHotels(tmpList)
                    hotelTagAddStatus=true;
                }
                let tags = (document.getElementById('myTagsHotels') as HTMLInputElement);
                if(tags.value!='')tagifyHotel.removeAllTags();
                if(tmpSelList.length>0) {
                    tagifyHotel.addTags(tmpSelList);
                }
                return tmpList;
            } else {
                return [];
            }
        });

        function testFunc(){
            tagifyHotel.removeAllTags();
            //tagifyHotel.addTags(tmpSelList);
        }

        let tagifyMarket;
        let tagifyHotel;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInput]');
            tagifyMarket = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }
        const setTagInputHotels = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputHotel]');
            tagifyHotel = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }

        function onHotelChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemHotel = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.itemHotel = tmpObj;
            }
        }

        function onMarketChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemMarket = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.itemMarket = tmpObj;
            }
        }

        return {
            myDetails,
            myDetails2,
            newItemData,
            myTagsMarkets,
            myTagsHotels,
            validationSchema,
            submit,
            onHotelChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            dateConvert,
            hotelTagAddStatus,
            marTagAddStatus,
            testFunc,
            tmpSelList,
            monthsList,
            arrAdKickBackTargetAmount,
        };
    },
});
