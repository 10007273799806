
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import accountingCodesType from "@/core/data/accountingCodesType";
import currency from "@/core/data/currency";
import {Field} from "vee-validate";

interface addAccountingCodes {
  Category: string;
  Title: string;
  Cur: string;
}


export default defineComponent({
  name: "accounting-codes",
  components: {
    Field,
  },

  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    function sendAccountingCodeList(prmType, prmTypeID) {
      ccvTypeID.value = prmTypeID;
      ccvType.value = prmType;

      const payloadit = {
        "TypeID": prmTypeID,
        "Type": prmType,}
      store.dispatch(Actions.ACCOUNTING_CODE_LIST, payloadit);
      console.log("sendAccountingCodeList start"  )
    }

    const ccvTypeID = ref(0);
    const ccvType = ref("");

    const addAccountingCodes = ref<addAccountingCodes>({
      Category: "",
      Title: "",
      Cur: "",
    });


    //store.dispatch(Actions.ACCOUNTING_CODE_LIST, {Type: "", TypeID: props.itemID});
    const dataAccountingCode = computed(() => {
      return store.getters.accountingCodeList;
    });

    const clickSubmit = () => {

      const accPayload = {
        Title: addAccountingCodes.value.Title,
        Category: addAccountingCodes.value.Category,
        Cur: addAccountingCodes.value.Cur,
        Type: ccvType.value,
        TypeID: ccvTypeID.value,
      }

      store.dispatch(Actions.ACCOUNTING_CODE_INSERT, accPayload)
          .then(() => {
            store.dispatch(Actions.ACCOUNTING_CODE_LIST, {Type: ccvType.value, TypeID: ccvTypeID.value});
            addAccountingCodes.value.Title = ""; addAccountingCodes.value.Category = ""; addAccountingCodes.value.Cur = "";
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", confirmButtonText: "Geri Dön!",
              icon: "success", buttonsStyling: false, customClass: { confirmButton: "btn fw-bold btn-light-primary",},
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getAccountingCodeErrors[0], confirmButtonText: "Tekrar dene!",
          icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger", },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    const deleteAccCode = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const delAccCode = {
            ID: ID,
          }
          store.dispatch(Actions.ACCOUNTING_CODE_DELETE, delAccCode)
              .then(() => {
                store.dispatch(Actions.ACCOUNTING_CODE_LIST, {Type: ccvType.value, TypeID: ccvTypeID.value});
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getAccountingCodeErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };

    return {
      accountingCodesType,
      currency,
      clickSubmit,
      deleteAccCode,
      addAccountingCodes,
      submitButtonRef,
      dataAccountingCode,
      sendAccountingCodeList,

    };
  },
});
