const monthsList = {
  "MONTHS" : [
    {
      "Value" : '1',
      "Title" : 'Ocak',
      "Shorthand" : 'Ock',
    },
    {
      "Value" : '2',
      "Title" : 'Şubat',
      "Shorthand" : 'Şbt',
    },
    {
      "Value" : '3',
      "Title" : 'Mart',
      "Shorthand" : 'Mrt',
    },
    {
      "Value" : '4',
      "Title" : 'Nisan',
      "Shorthand" : 'Nsn',
    },
    {
      "Value" : '5',
      "Title" : 'Mayıs',
      "Shorthand" : 'May',
    },
    {
      "Value" : '6',
      "Title" : 'Haziran',
      "Shorthand" : 'Haz',
    },
    {
      "Value" : '7',
      "Title" : 'Temmuz',
      "Shorthand" : 'Tem',
    },
    {
      "Value" : '8',
      "Title" : 'Ağustos',
      "Shorthand" : 'Ağs',
    },
    {
      "Value" : '9',
      "Title" : 'Eylül',
      "Shorthand" : 'Eyl',
    },
    {
      "Value" : '10',
      "Title" : 'Ekim',
      "Shorthand" : 'Ekm',
    },
    {
      "Value" : '11',
      "Title" : 'Kasım',
      "Shorthand" : 'Ksm',
    },
    {
      "Value" : '12',
      "Title" : 'Aralık',
      "Shorthand" : 'Ara',
    }],
};
export default monthsList;
