const accountingCodesType = {
  "TYPES" : [
    {
      Value : 'ACCOUNT',
      Title : 'Muhasebe',
    },
    {
      Value : 'CURRENT',
      Title : 'Cari',
    },
  ],
};
export default accountingCodesType;
