const infoList = {
  "INVOICE" : [
    {
      "Value" : '1',
      "Title" : '1 GÜN SONRA',
    },
    {
      "Value" : '3',
      "Title" : '3 GÜN SONRA',
    },
    {
      "Value" : '5',
      "Title" : '5 GÜN SONRA',
    },
    {
      "Value" : '7',
      "Title" : '1 HAFTA SONRA',
    },
    {
      "Value" : '10',
      "Title" : '10 GÜN SONRA',
    },
    {
      "Value" : '14',
      "Title" : '2 HAFTA SONRA',
    },
    {
      "Value" : '15',
      "Title" : '15 GÜN SONRA',
    },
    {
      "Value" : '20',
      "Title" : '20 GÜN SONRA',
    },
    {
      "Value" : '30',
      "Title" : '1 AY SONRA',
    },
    {
      "Value" : '45',
      "Title" : '45 GÜN SONRA',
    },
    {
      "Value" : '60',
      "Title" : '2 AY SONRA',
    },
    {
      "Value" : '75',
      "Title" : '75 GÜN SONRA',
    },
    {
      "Value" : '90',
      "Title" : '3 AY SONRA',
    }],
  "CHECKIN" : [
    {
      "Value" : '1',
      "Title" : '1 GÜN ÖNCE',
    },
    {
      "Value" : '3',
      "Title" : '3 GÜN ÖNCE',
    },
    {
      "Value" : '5',
      "Title" : '5 GÜN ÖNCE',
    },
    {
      "Value" : '7',
      "Title" : '1 HAFTA ÖNCE',
    },
    {
      "Value" : '10',
      "Title" : '10 GÜN ÖNCE',
    },
    {
      "Value" : '14',
      "Title" : '2 HAFTA ÖNCE',
    },
    {
      "Value" : '15',
      "Title" : '15 GÜN ÖNCE',
    }],
  "PERIOD" : [
    {
      "Value" : '3',
      "Title" : '3 GÜNLÜK',
    },
    {
      "Value" : '5',
      "Title" : '5 GÜNLÜK',
    },
    {
      "Value" : '7',
      "Title" : '1 HAFTALIK',
    },
    {
      "Value" : '10',
      "Title" : '10 GÜNLÜK',
    },
    {
      "Value" : '14',
      "Title" : '2 HAFTALIK',
    },
    {
      "Value" : '15',
      "Title" : '15 GÜNLÜK',
    },
    {
      "Value" : '20',
      "Title" : '20 GÜNLÜK',
    },
    {
      "Value" : '30',
      "Title" : '1 AYLIK',
    },
    {
      "Value" : '45',
      "Title" : '45 GÜNLÜK',
    },
    {
      "Value" : '60',
      "Title" : '2 AYLIK',
    }]
};
export default infoList;
