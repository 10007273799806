
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import {dateConvert} from "@/core/filters/datatime";
import monthsList from "@/core/data/months";

interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemMarket: string;
    itemHotel: string;
}

export default defineComponent({
    name: "new-over-commission-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    mounted() {
        this.datapicker();
        return true;
    },
    methods: {
        datapicker() {
            let config = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
            };
            this.startpicker = flatpickr("#kt_datepicker_1", config);
            this.endpicker = flatpickr("#kt_datepicker_2", config);
        },
    },
    props: {
        accountID: String,
    },
    setup() {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);
        const arrAdKickBackTargetAmount = ref ([
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
            {  Advertisment : "",Kickback : "",TargetAmount: "",},
        ]);


        const myDetails2 = computed(() => {
            return store.getters.accountDetails;
        });



        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemMarket: "",
            itemHotel: "",
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Bitiş Tarihi"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            const payloadInsertKickBack = {
                AccountID: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                Markets: JSON.stringify(newItemData.value.itemMarket),
                Hotels: JSON.stringify(newItemData.value.itemHotel),
                Type:"",
                Amount:"",
                CalculateType:"",
                AdKickBackTargetAmount: JSON.stringify(arrAdKickBackTargetAmount.value),
            }
            //console.log(payloadInsertKickBack);

            store.dispatch(Actions.ACCOUNT_KICKBACK_INSERT, payloadInsertKickBack)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", confirmButtonText: "Geri Dön!",
                        icon: "success", buttonsStyling: false,  customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getAccountKickBackErrors[0], confirmButtonText: "Tekrar dene!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.HOTEL_LIST_ALL, {});
        const myHotels = computed(() => {
            const tmp= store.getters.hotelList;
            //console.log(tmp)
            return tmp;
        });

        const myTagsMarkets = computed(() => {
            console.log("AccKickBackAddNew myTagsMarkets started ");
            if (myMarkets.value.data) {
                let tmpList = [];
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'Market',
                        ID: myMarkets.value.data[i].ID,
                    };
                }
                setTagInputMarkets(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });

        const myTagsHotels = computed(() => {
            console.log("AccKickBackAddNew myTagsHotels started ");
            if (myHotels.value.data!== undefined) {
                let tmpList = [];
                for (let i = 0; i < myHotels.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myHotels.value.data[i].Title).trim(),
                        title: (myHotels.value.data[i].Title).trim(),
                        type: 'Hotel',
                        ID: myHotels.value.data[i].ID,
                    };

                }
                setTagInputHotels(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });


        let tagify;
        let tagify2;
        const setTagInputMarkets = (wList) => {
            console.log("AccKickBackAddNew setTagInputMarkets started ");
            var input = document.querySelector('input[name=myTagsInputMarkets]');
// init Tagify script on the above inputs
            tagify = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const setTagInputHotels = (wList) => {
            console.log("AccKickBackAddNew setTagInputHotels started ");
            var input = document.querySelector('input[name=myTagsInputHotels]');
// init Tagify script on the above inputs
            tagify2 = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        function onHotelChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemHotel = tmpObj;
        }

        function onMarketChange(e) {
            //console.log(e.target.value)
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemMarket = tmpObj;
        }

        return {
            myDetails2,
            newItemData,
            myTagsMarkets,
            myTagsHotels,
            validationSchema,
            submit,
            onHotelChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            dateConvert,
            arrAdKickBackTargetAmount,
            monthsList,
        };
    },
});
