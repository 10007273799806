
import {computed, defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ErrorMessage, Field, Form} from "vee-validate";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";
import Tagify from '@yaireo/tagify';
import infoList from "@/core/data/accountworktypeoption";
import FileList from "@/components/common/FileList.vue";
import {hideModal} from "@/core/helpers/dom";
import currency from "@/core/data/currency";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import AccountingCodesModal from "@/components/general-modals/accounting/AccountingCodes.vue";
import KickBack from "@/views/apps/sys/account/KickBack.vue";


interface addSystemUsers {
    Name: string;
    Lastname: string;
    Username: string;
    Password: string;
    Password2: string;
    Email: string;
    Mobile: string;

}
export default defineComponent({
    name: "account-settings",
    data: function () {
        return {}
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FileList,
        AccountingCodesModal,
        KickBack,
    },
    methods: {
        ...dateConvert.method,
        ...commonf
    },
    computed: {},
    setup() {
        const route = useRoute();
        const id = route.params.id;
        const payload = {
            ID: id
        }
        onMounted(() => {
            setCurrentPageBreadcrumbs("Güncelle", ["Cari Hesaplar"]);

        });

        let refComp_AccountingCodeList = ref(null);



        const addSystemUsers = ref<addSystemUsers>({
            Name: "",
            Lastname: "",
            Username: "",
            Password: "",
            Password2: "",
            Email: "",
            Mobile: "",
        });


        const addSystemUserValidator = Yup.object().shape({
            Name: Yup.string().required("İsim girilmemiş"),
            Lastname: Yup.string().required("Soyisim girilmemiş"),
            Username: Yup.string().required("Kullanıcı adı girilmemiş"),
            Email: Yup.string().required("E-mail girilmemiş"),
            Mobile: Yup.string().required("Telefon girilmemiş"),
            Password: Yup.string().label("Plable").matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Şifre en az 8 karakter ve büyük harf - küçük harf - numara  ve özel karakter('@',# vs) içermelidir."
            ),
            Password2: Yup.string().oneOf([Yup.ref('Password'), null], 'Girdiğiniz Şifre ile Onay Şifreniz eşleşmiyor.'),

        });

        const updateSystemUserValidator = Yup.object().shape({
            Name: Yup.string().required("İsim girilmemiş"),
            Lastname: Yup.string().required("Soyisim girilmemiş"),
            Username: Yup.string().required("Kullanıcı adı girilmemiş"),
            Email: Yup.string().required("E-mail girilmemiş"),
            Mobile: Yup.string().required("Telefon girilmemiş"),
            Password: Yup.string().label("Plable").matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Şifre en az 8 karakter ve büyük harf - küçük harf - numara  ve özel karakter('@',# vs) içermelidir."
            ),
            Password2: Yup.string().oneOf([Yup.ref('Password'), null], 'Girdiğiniz Şifre ile Onay Şifreniz eşleşmiyor.'),

        });

        const payload_3 = {
            AccountID: id
        }

        store.dispatch(Actions.USER_LIST, payload_3).then((prmData) => {
            arrSystemUserList.value = prmData.data;
        });
        const arrSystemUserList = ref ([]);


        const submitAddSystemUser = () => {
            if (!submitButtonSystemUserAdd.value) {
                return;
            }
            const systemUserPayload = {
                Type: "ACCOUNT",
                Avatar: "150-26.jpg",
                Status: "B2B",
                AccountID: route.params.id,
                CompanyID: "1",
                BranchID: "1",
                UserGroupID: "1",
                TitleID: "3",
                DepartmentID: "1",
                City: "Lefkoşa",
                Country: "TRNC",
                Language: "TR",
                BankAccount: "",
                ShiftType: "FULL-TIME",
                Wage: 0,
                WageCur: "TRY",
                WageType: "MONTHLY",
                WageDay: "7",
                StartDate: "2022-11-11",
                Name: addSystemUsers.value.Name,
                Lastname: addSystemUsers.value.Lastname,
                Username: addSystemUsers.value.Username,
                Password: addSystemUsers.value.Password,
                Password2: addSystemUsers.value.Password2,
                Email: addSystemUsers.value.Email,
                Mobile: addSystemUsers.value.Mobile,
            }

            if(addSystemUsers.value.Password!==addSystemUsers.value.Password2){
                libTools.method.swalError("Tamam", "Şifreniz uyuşmuyor");
            }else{
                store.dispatch(Actions.USER_INSERT, systemUserPayload)
                    .then(() => {
                        store.dispatch(Actions.USER_LIST, {});
                        Swal.fire({text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", icon: "success",
                            buttonsStyling: false, confirmButtonText: "Geri Dön!", customClass: { confirmButton: "btn fw-bold btn-light-primary",},
                        }).then(function () {
                            submitButtonSystemUserAdd.value.disabled = false;
                            submitButtonSystemUserAdd.value.setAttribute("data-kt-indicator", "off");
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                    }).catch(() => {
                    Swal.fire({ text: store.getters.getUserErrors[0], icon: "error",
                        buttonsStyling: false,  confirmButtonText: "Tekrar dene!", customClass: {  confirmButton: "btn fw-bold btn-light-danger", },
                    });
                    submitButtonSystemUserAdd.value.disabled = false;
                    submitButtonSystemUserAdd.value.setAttribute("data-kt-indicator", "off");
                });
            }

        }


        const deleteSystemUser = (ID) => {
            store.dispatch(Actions.USER_LIST, {});
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload1 = {
                        ID: ID,
                    }
                    store.dispatch(Actions.USER_DELETE, payload1)
                        .then(() => {
                            store.dispatch(Actions.USER_LIST, {});
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });
        };


        function clickUpdateSystemUser(prmSystmeUser) {
            store.dispatch(Actions.USER_DETAILS, {ID: prmSystmeUser});
        }

        const dataSystemUserDetails = computed(() => {
            const datacomputed = store.getters.userDetails;
            if(datacomputed!==null && datacomputed!==undefined ){
                console.log(JSON.stringify(datacomputed) );
                //console.log(JSON.stringify(datacomputed.data) );

                setSystemUserDetail(datacomputed);
            }
            return datacomputed;
        })

        const mySystemUserDetails = ref<any>({});


        function setSystemUserDetail(prmData) {
            mySystemUserDetails.value = prmData;
            //mySystemUserDetails.value.Password2 = prmData.Password;
        }

        const submitUpdateSystemUser = () => {
            if (!submitButtonSystemUserUpdate.value) {
                return;
            }

            const payload1 = {
                ID: (mySystemUserDetails.value as any).ID,
                Name: (mySystemUserDetails.value as any).Name,
                Lastname: (mySystemUserDetails.value as any).Lastname,
                Username: (mySystemUserDetails.value as any).Username,
                Password: (mySystemUserDetails.value as any).Password,
                Password2: (mySystemUserDetails.value as any).Password2,
                Email: (mySystemUserDetails.value as any).Email,
                Mobile: (mySystemUserDetails.value as any).Mobile,

            }

            store.dispatch(Actions.USER_EDIT, payload1)
                .then(() => {
                    store.dispatch(Actions.USER_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {

                        submitButtonSystemUserUpdate.value.disabled = false;
                        submitButtonSystemUserUpdate.value.setAttribute("data-kt-indicator", "off");
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonSystemUserUpdate.value.disabled = false;
                submitButtonSystemUserUpdate.value.setAttribute("data-kt-indicator", "off");
            });
        }



        store.dispatch(Actions.ACCOUNT_DETAILS, payload).then((prmData) => {
            myDetails.value = prmData.data;
        });

        const myDetails = ref ({});
        /*const myDetails = computed(() => {
          return store.getters.accountDetails;
        });*/

        store.dispatch(Actions.LANGUAGES_LIST_CACHE, {});
        const myLanguage = computed(() => {
            return store.getters.LanguagesListCache;
        });

        const payload_2 = {
            Type: 'ACCOUNT'
        }
        store.dispatch(Actions.TAGS_LIST, payload_2);
        const myTags = computed(() => {
            return store.getters.tagsList;
        });
        const myTagsList1 = computed(() => {
            if (myTags.value.data) {
                let tmpList = [];
                for (let i = 0; i < myTags.value.data.length; i++) {
                    tmpList[i] = {
                        value: myTags.value.data[i].Title,
                        title: myTags.value.data[i].Title,
                        ID: myTags.value.data[i].ID,
                    };

                }
                setTagInput(tmpList);
                return tmpList;

            } else {
                return [];
            }
        });

        store.dispatch(Actions.ACCOUNT_TAGS_LIST, payload_3);
        const myTagValues = computed(() => {
            return store.getters.accountTagsList;
        });

        const myTagsSelected = computed(() => {
            if (myTagValues.value.data) {
                let tmpList = [];
                for (let i = 0; i < myTagValues.value.data.length; i++) {
                    tmpList[i] = {
                        value: myTagValues.value.data[i].Title,
                        title: myTagValues.value.data[i].Title,
                        ID: myTagValues.value.data[i].ID,
                    };

                }
                tagify.addTags(tmpList);
                var input = document.querySelector('input[name=myTagsInput]');
                input.addEventListener("change", onChange, false);

                return tmpList;
            } else {
                return [];
            }
        });


        const submitButton1 = ref<HTMLElement | null>(null);
        const submitButton2 = ref<HTMLElement | null>(null);
        const submitButton3 = ref<HTMLElement | null>(null);
        const submitButton4 = ref<HTMLElement | null>(null);
        const deactivateAccountButton = ref<HTMLElement | null>(null);
        const accountSale = ref(null);
        const accountActive = ref(null);
        const myNameStatus = ref('valid');
        const submitButtonSystemUserAdd = ref<null | HTMLButtonElement>(null);
        const submitButtonSystemUserUpdate = ref<null | HTMLButtonElement>(null);


        let isActive;
        let isSale;

        let isAccountSale = computed(() => {
            return ((myDetails.value as any).is_sale == 'open') ? true : false;
        });

        let isAccountActive = computed(() => {
            return ((myDetails.value as any).is_active == 'active') ? true : false;
        });

        const profileDetailsValidator = Yup.object().shape({
            accountTitle: Yup.string().required().min(3).label("İsim"),
            accountCountry: Yup.string().required().label("Ülke"),
            accountCity: Yup.string().required().label("Şehir"),
            accountEmail: Yup.string().required().email().label("Email"),
            accountAddress: Yup.string().required().label("Adres"),
            accountPhone: Yup.string().required().label("Telefon"),
            accountLanguage: Yup.string().required().label("Dil"),
        });


        const deactiveAccount = Yup.object().shape({
            deactivate: Yup.string().required().label("Hesap Silme Onayı"),
        });


        const saveChanges1 = () => {
            if (submitButton1.value) {
                // Activate indicator
                submitButton1.value.setAttribute("data-kt-indicator", "on");
                const payload1 = {
                    ID: (myDetails.value as any).ID,
                    Title: (myDetails.value as any).Title,
                    City: (myDetails.value as any).City,
                    Country: (myDetails.value as any).Country,
                    Address: (myDetails.value as any).Address,
                    Email: (myDetails.value as any).Email,
                    Web: (myDetails.value as any).Web,
                    Phone: (myDetails.value as any).Phone,
                    Language: (myDetails.value as any).Language,
                    KPlusChannelCode: (myDetails.value as any).KPlusChannelCode,
                    KPlusChannelPassword: (myDetails.value as any).KPlusChannelPassword,
                }

                store.dispatch(Actions.ACCOUNT_EDIT, payload1)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton1.value?.removeAttribute("data-kt-indicator");
                    }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                    submitButton1.value?.removeAttribute("data-kt-indicator");
                });
            }
        };

        const saveChanges2 = () => {
            if (submitButton2.value) {
                // Activate indicator
                submitButton2.value.setAttribute("data-kt-indicator", "on");

                const payload2 = {
                    ID: (myDetails.value as any).ID,
                    AccountingEmail: (myDetails.value as any).AccountingEmail,
                    AccountingPhone: (myDetails.value as any).AccountingPhone,
                    SaleEmail: (myDetails.value as any).SaleEmail,
                    SalePhone: (myDetails.value as any).SalePhone,
                    OperationEmail: (myDetails.value as any).OperationEmail,
                    OperationPhone: (myDetails.value as any).OperationPhone,
                }

                store.dispatch(Actions.ACCOUNT_EDIT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getAccountErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const saveChanges3 = () => {
            if (submitButton3.value) {
                submitButton3.value.setAttribute("data-kt-indicator", "on");
                const payload3 = {
                    ID: (myDetails.value as any).ID,
                    Type: (myDetails.value as any).Type
                }
                console.log(payload3);
                store.dispatch(Actions.ACCOUNT_EDIT, payload3)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton3.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton3.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const saveChanges4 = () => {
            if (submitButton4.value) {
                // Activate indicator
                submitButton4.value.setAttribute("data-kt-indicator", "on");
                isActive = (accountActive.value.checked) ? 'active' : 'passive';
                isSale = (accountSale.value.checked) ? 'open' : 'stop';

                const payload2 = {
                    ID: (myDetails.value as any).ID,
                    is_active: isActive,
                    is_sale: isSale,
                }

                store.dispatch(Actions.ACCOUNT_EDIT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton4.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton4.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const saveChanges5 = () => {
            if (submitButton2.value) {
                // Activate indicator
                submitButton2.value.setAttribute("data-kt-indicator", "on");

                const payload2 = {
                    ID: (myDetails.value as any).ID,
                    CompanyName: (myDetails.value as any).CompanyName,
                    Address: (myDetails.value as any).Address,
                    Phone: (myDetails.value as any).Phone,
                    Email: (myDetails.value as any).Email,
                    TaxOffice: (myDetails.value as any).TaxOffice,
                    TaxNumber: (myDetails.value as any).TaxNumber,
                    WorkType: (myDetails.value as any).WorkType,
                    WorkTypeOptions: (myDetails.value as any).WorkTypeOptions,
                }

                store.dispatch(Actions.ACCOUNT_EDIT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getAccountErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const deactivateAccount = () => {
            if (deactivateAccountButton.value) {
                // Activate indicator
                deactivateAccountButton.value.setAttribute("data-kt-indicator", "on");

                const payload6 = {
                    ID: (myDetails.value as any).ID,
                }
                console.log(payload6);
                store.dispatch(Actions.ACCOUNT_DELETE, payload6)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde silme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully delete
                            //router.push({name: "userlist"});
                            //this.$router.push({name: "hotellist"});
                        });
                        deactivateAccountButton.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        deactivateAccountButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };

        const nameControl = (e) => {
            myNameStatus.value = '';
            if ((myDetails.value as any).Title.length >= 3) {

                let button;
                let icon;
                button = document.querySelector("#kt_button_chkName");
                icon = document.querySelector("#icon_chkName");
                button.setAttribute("data-kt-indicator", "on");
                let newClass = "";

                const payload_2 = {
                    Title: (myDetails.value as any).Title
                }
                store.dispatch(Actions.ACCOUNT_LIST_ALL, payload_2)
                    .then(() => {
                        let myCheckList = store.getters.accountList;
                        if ((myCheckList.data.length > 1 || (myCheckList.data[0].ID != id && myCheckList.data.length == 1))) {
                            newClass = "las la-exclamation-circle fs-2x me-1 text-danger";
                        } else {
                            myNameStatus.value = 'valid';
                            newClass = "las la-plus-circle fs-2x me-1 text-success";
                        }
                        icon.setAttribute("class", newClass);
                        button.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        myNameStatus.value = 'valid';
                        newClass = "las la-plus-circle fs-2x me-1 text-success";
                        icon.setAttribute("class", newClass);
                        button.removeAttribute("data-kt-indicator");
                    });
            }

        }

        let tagify;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
            tagify = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                skipInvalid: true, // do not remporarily add invalid tags
                transformTag: transformTag,
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }

        function getRandomColor() {
            function rand(min, max) {
                return min + Math.random() * (max - min);
            }

            var h = rand(1, 360) | 0,
                s = rand(40, 70) | 0,
                l = rand(65, 72) | 0;
            return 'hsl(' + h + ',' + s + '%,' + l + '%)';
        }

        function transformTag(tagData) {
            tagData.color = getRandomColor();
            //tagData.style = "--tag-bg:" + tagData.color;
            tagData.style = " --tag-text-color:" + tagData.color;

            if (tagData.value.toLowerCase() == 'shit')
                tagData.value = 's✲✲t'
        }

        function onChange(e) {
            // outputs a String
            //console.log(JSON.parse(e.target.value));
            let tmpObj = JSON.parse(e.target.value);
            let payload = {
                editItem: {
                    AccountID: id
                },
                data: []
            };

            for (let i = 0; i < tmpObj.length; i++) {
                payload.data[i] = {
                    AccountID: id,
                    TagID: tmpObj[i].ID,
                };
            }
            store.dispatch(Actions.ACCOUNT_TAGS_EDIT_LIST, payload);
        }

        const filteredWorkTypeInfo = computed(() => {
            return infoList[(myDetails.value as any).WorkType];
        });

        store.dispatch(Actions.CITY_LIST, {});
        const myCities = computed(() => {
            return store.getters.CityList;
        });

        store.dispatch(Actions.COUNTRY_LIST, {});
        const myCountry = computed(() => {
            return store.getters.countryList;
        });

        const filteredCity = computed(() => {
            if (myCities.value.data != null) {
                return (myCities.value.data).filter(citiesItem =>
                    citiesItem.Country == ((myDetails.value as any).Country).toLowerCase()
                );
            } else {
                return true;
            }
        });

        function setItems(ID) {
            (refComp_AccountingCodeList.value as any).sendAccountingCodeList("ACCOUNT", ID);
        }




        return {
            currency,
            libTools,
            id,
            myDetails,
            myLanguage,
            isAccountSale,
            isAccountActive,
            myNameStatus,
            myCities,
            myCountry,
            nameControl,
            filteredCity,
            accountActive,
            accountSale,
            submitButton1,
            submitButton2,
            submitButton3,
            submitButton4,
            saveChanges1,
            saveChanges2,
            saveChanges3,
            saveChanges4,
            saveChanges5,
            deactivateAccount,
            profileDetailsValidator,
            deactiveAccount,
            deactivateAccountButton,
            onChange,
            myTagsList1,
            myTags,
            myTagsSelected,
            filteredWorkTypeInfo,
            setItems,
            refComp_AccountingCodeList,
            arrSystemUserList,
            addSystemUsers,
            submitAddSystemUser,
            submitButtonSystemUserAdd,
            addSystemUserValidator,
            deleteSystemUser,
            mySystemUserDetails,
            submitButtonSystemUserUpdate,
            submitUpdateSystemUser,
            updateSystemUserValidator,
            clickUpdateSystemUser,
            dataSystemUserDetails,

        };
    },
});
