
import {computed, defineComponent} from "vue";
import FileAddNew from "@/components/common/FileAddNew.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MasterConfig from "@/core/config/MasterConfig";
import { dateConvert } from "@/core/filters/datatime"

export default defineComponent({
  name: "kt-widget-10",
  components: {
    FileAddNew,
  },
  data: function () {
    return {
      myRoomID : 0,
    }
  },
  methods: {
    ...dateConvert.method,
    setRoomID(ID){
      this.myRoomID = ID;
    },

  },
  props: {
    widgetClasses: String,
    ID: String,
    fileType: String,
    fileParentID: String,
    fileChildID: String,
    fileSubChildID: String,
  },
  setup(props) {
    const payload = {
      Type: props.fileType,
      ParentID: props.fileParentID,
      ChildID: (props.fileChildID) ? props.fileChildID : 'NULL',
      SubChildID: (props.fileSubChildID) ? props.fileSubChildID : 'NULL',
    }
    store.dispatch(Actions.FILES_LIST, payload);
    const myFiles = computed(() => {
      return store.getters.filesList;
    });
    const showFile = (path) =>{
      return MasterConfig.general.imagePath+path;
    }
    const deleteFile = (ID) => {

      Swal.fire({
        title: 'Eminmisiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
            Type: props.fileType,
            ParentID: props.fileParentID,
            ChildID: (props.fileChildID) ? props.fileChildID : 'NULL',
            SubChildID: (props.fileSubChildID) ? props.fileSubChildID : 'NULL',
          }
          store.dispatch(Actions.FILES_DELETE, payload1)
              .then(() => {
                Swal.fire(
                  'Silindi!',
                  'Kayit silme işlemi tamamlandı.',
                  'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelRoomsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };
    return {
      myFiles,
      deleteFile,
      showFile,
    };
  },
});
